import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { TextSection } from "../../../Shared/Layout/components/TextSection/TextSection";

export interface BriefSolutionsSectionProps {
  className?: string;
}

const BriefSolutionsSectionInner = (props: BriefSolutionsSectionProps) => {
  const { t } = useTranslation("translations");

  return (
    <TextSection
      className={props.className}
      title={t("inspirationalWorkshopsPage.briefConsultations.title")}
      description={t("inspirationalWorkshopsPage.briefConsultations.text")}
    />
  );
};

export const BriefSolutionsSection = styled(BriefSolutionsSectionInner)``;
