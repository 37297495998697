import Grid from "@material-ui/core/Grid";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { CustomAccordion } from "../../../../Shared/CustomAccordion/CustomAccordion";

export interface ExpectationDescriptionProps {
  className?: string;
}

const ExpectationDescriptionInner = (props: ExpectationDescriptionProps) => {
  const { t } = useTranslation("translations");
  const markdownSource = `
  * Dokonca sme veľa krát „pre-kvalifikovaní“ a o danej oblasti máme dostatočné množstvo informácií. To čo nás prijme konať a pohnúť sa z miesta, je schované niekde inde : v našom pohľade na vec, v našom postoji,  v našej vôli, v našom odhodlaní, v našom dostatku alebo nedostatku energie, v našej radosti ... Na prácu s týmito faktormi sa zameriavam v inšpiratívnom vzdelávaní.
   These are the factors I work with in inspirational workshops.
  * Nie je mojou snahou vystupovať v daných témach ako „pravdu-majúca autorita“ . Naopak. Vytváram priestor pre vlastný názor ako aj pre prípadný nesúhlas. Kladiem dôraz na to, aby som ponechal účastníkom možnosť – samým objaviť “svoje pravdy” , a to prostredníctvom vlastného zážitku.
  `;

  return (
    <Grid className={props.className} item md={9} sm={7} xs={12}>
      <h3>
        Často krát sa stáva, že nám nechýbajú ďalšie vedomosti a zručnosti...
      </h3>
      <Description>
        <ReactMarkdown source={markdownSource} />
      </Description>
    </Grid>
  );
};

export const ExpectationDescription = styled(ExpectationDescriptionInner)``;
const Description = styled.div`
  padding-top: 20px;
  /* //TODO: color of dots  */
  li {
    color: ${(props) => props.theme.color.gray};
    padding-top: 5px;
  }
`;
