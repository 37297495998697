import { FluidObject } from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { useInspirationalWorkshopHeaderImages } from "./useInspirationalWorkshopHeaderImages";
import { string } from "prop-types";

export interface Service {
  image: FluidObject;

  title: string;
  content: string;

  shortText: string;

  reference: {
    name: string;
    reference: string;
    image: FluidObject;
  };
}

export const useInspirationWorkshopsServices = () => {
  const { strapiWorkshops: { services } } = useStaticQuery(graphql`
    query useInspirationWorkshopsServices {
      strapiWorkshops {
        services {

          title
          content: text
          shortText
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          reference {
            name: title
            reference: text
            image {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }

        }
      }
    }
  `);

  return services;
};
