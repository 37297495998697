import React from "react";
import styled from "styled-components";
import { ServiceReference } from "../../shared/ServiceReference/ServiceReference";
import {
  ServiceSection,
  ServiceSectionProps
} from "../../shared/ServiceSection/ServiceSection";
import { useInspirationWorkshopsServices } from "./hooks/useInspirationWorkshopsServices";

export interface InsirationalWorkshopsServicesSectionProps {
  className?: string;
}

const InsirationalWorkshopsServicesSectionInner = (
  props: InsirationalWorkshopsServicesSectionProps
) => {
  const services = useInspirationWorkshopsServices();

  return (
    <div className={props.className}>
      {services.map(service => (
        <ServiceSection
          key={service.title}
          title={service.title}
          content={service.content}
          image={service.image.childImageSharp.fluid}
          reference={
            service?.reference?.name && (
              <ServiceReference {...service.reference} />
            )
          }
        />
      ))}
    </div>
  );
};

export const InsirationalWorkshopsServicesSection = styled(
  InsirationalWorkshopsServicesSectionInner
)``;
