import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";
import { Section } from "../../../Shared/Layout/components/Section/Section";

import { ExpectationDescription } from "./components/ExpectationDescription";
import { ImageLeftSide } from "./components/ImageLeftSide";

export interface WhatCanYouExpectSectionProps {
  className?: string;
}

const WhatCanYouExpectSectionInner = (props: WhatCanYouExpectSectionProps) => {
  return (
    <Section className={props.className}>
      <Container>
        <Grid container spacing={4}>
          <ImageLeftSide />
          <ExpectationDescription />
        </Grid>
      </Container>
    </Section>
  );
};

export const WhatCanYouExpectSection = styled(WhatCanYouExpectSectionInner)`
  background-color: ${props => props.theme.color.sectionBg};
  padding: 30px 0;
`;
