import React from "react";
import { BriefSolutionsSection } from "../../components/Services/InspirationalWorkhops/BriefSolutionsSection/BriefSolutionsSection";
import { InsirationalWorkshopsServicesSection } from "../../components/Services/InspirationalWorkhops/InsirationalWorkshopsServicesSection/InsirationalWorkshopsServicesSection";
import { WhatCanYouExpectSection } from "../../components/Services/InspirationalWorkhops/WhatCanYouExpectSection/WhatCanYouExpectSection";
import { WorkshopsSectionLinks } from "../../components/Services/InspirationalWorkhops/WorkshopsSectionLinks/WorkshopsSectionLinks";
import { TopHeader } from "../../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../../components/Shared/Layout/Layout";

///TODO: title of topheader treba vsade prelozit
const InspirationalWorkshopsPage = () => (
  <Layout>
    <TopHeader title="Služby / Inšpiratívne workshopy" />
    <BriefSolutionsSection />
    <WhatCanYouExpectSection />
    <WorkshopsSectionLinks />
    <InsirationalWorkshopsServicesSection />
  </Layout>
);

export default InspirationalWorkshopsPage;
