import { Container } from "@material-ui/core";
import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ShortText } from "../../../Home/LetsStartSection/components/RightSide/WhyThisJobBox";
import {
  ButtonAndShortText,
  ButtonAndShortTextProps,
  ButtonShortText
} from "../../../Shared/Button/ButtonAndShortText/ButtonAndShortText";
import { Button } from "../../../Shared/Button/Primary/Button";
import { Section } from "../../../Shared/Layout/components/Section/Section";
import { muiTheme } from "../../../Shared/Theme/Theme";
import { SectionLinks } from "../../shared/SectionLinks/SectionLinks";
import { useInspirationWorkshopsServices } from "../InsirationalWorkshopsServicesSection/hooks/useInspirationWorkshopsServices";

export interface WorkshopsSectionLinksProps {
  className?: string;
}

const WorkshopsSectionLinksInner = (props: WorkshopsSectionLinksProps) => {
  const { t } = useTranslation("translations");
  const links = useMemo(
    () =>
      Object.values(useInspirationWorkshopsServices()).map(
        (service): ButtonAndShortTextProps => ({
          buttonTitle: service.title,
          buttonUrl: `#${service.title}`,
          shortText: service.shortText,
          buttonType: "primary"
        })
      ),
    []
  );

  return (
    <Section className={props.className}>
      <Container>
        <SectionLinks links={links} />
      </Container>
    </Section>
  );
};

export const WorkshopsSectionLinks = styled(WorkshopsSectionLinksInner)`
  ${SectionLinks} {
    justify-content: space-evenly;
  }

  ${muiTheme.breakpoints.up("sm")} {
    ${ButtonAndShortText} {
      min-width: unset;
    }

    ${Button} {
      min-width: 300px;
      width: 300px;

      margin: auto;
    }

    ${ButtonShortText} {
      max-width: 60%;
    }
  }
`;
